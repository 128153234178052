(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-workers"), require("@carto/react-api"), require("@material-ui/core"), require("@material-ui/core/styles"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-redux", "@carto/react-redux", "@carto/react-ui", "@carto/react-core", "@carto/react-workers", "@carto/react-api", "@material-ui/core", "@material-ui/core/styles"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactWidgets"] = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-workers"), require("@carto/react-api"), require("@material-ui/core"), require("@material-ui/core/styles"));
	else
		root["cartoReactWidgets"] = factory(root["react"], root["react-redux"], root["@carto/react-redux"], root["@carto/react-ui"], root["@carto/react-core"], root["@carto/react-workers"], root["@carto/react-api"], root["@material-ui/core"], root["@material-ui/core/styles"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__297__, __WEBPACK_EXTERNAL_MODULE__79__, __WEBPACK_EXTERNAL_MODULE__329__, __WEBPACK_EXTERNAL_MODULE__334__, __WEBPACK_EXTERNAL_MODULE__413__, __WEBPACK_EXTERNAL_MODULE__749__, __WEBPACK_EXTERNAL_MODULE__887__, __WEBPACK_EXTERNAL_MODULE__731__, __WEBPACK_EXTERNAL_MODULE__604__) {
return 