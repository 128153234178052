(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@turf/bbox-polygon"), require("@turf/boolean-intersects"));
	else if(typeof define === 'function' && define.amd)
		define(["@turf/bbox-polygon", "@turf/boolean-intersects"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactCore"] = factory(require("@turf/bbox-polygon"), require("@turf/boolean-intersects"));
	else
		root["cartoReactCore"] = factory(root["@turf/bbox-polygon"], root["@turf/boolean-intersects"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__780__, __WEBPACK_EXTERNAL_MODULE__111__) {
return 