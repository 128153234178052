(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"), require("@deck.gl/core"), require("@carto/react-core"), require("@carto/react-workers"));
	else if(typeof define === 'function' && define.amd)
		define(["@reduxjs/toolkit", "@deck.gl/core", "@carto/react-core", "@carto/react-workers"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactRedux"] = factory(require("@reduxjs/toolkit"), require("@deck.gl/core"), require("@carto/react-core"), require("@carto/react-workers"));
	else
		root["cartoReactRedux"] = factory(root["@reduxjs/toolkit"], root["@deck.gl/core"], root["@carto/react-core"], root["@carto/react-workers"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__139__, __WEBPACK_EXTERNAL_MODULE__966__, __WEBPACK_EXTERNAL_MODULE__413__, __WEBPACK_EXTERNAL_MODULE__749__) {
return 